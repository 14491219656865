/* import all seperate scss-files here for a combined css */
/**
 * This is the file for generic things, that aren't tied to any specific module/situation.
 * Such as default font for h1-6, p, and so on.
 */
/**
 * Various functions we want to be able to call from any other scss-file.
 */
/**
 * Various variables we want to be able to call from any other scss-file.
 * Such as color or default sizes if they're better suited as variable than a mixin.
 */
/*
  How to use @Keyframe()

div {
  @include Animation(navbar-stick 0.3s forwards);
}

@include Keyframe(navbar-stick) {
  0% {
    @include Transform(translateY(-100%));
  }
  100% {
    @include Transform(translateY(0%));
  }
}
*/
/**
 * Various variables we want to be able to call from any other scss-file.
 * Such as color or default sizes if they're better suited as variable than a mixin.
 */
html {
  font-size: 10px; }

/**
 * Even if spacer is technically its own module, it shouldn't be
 * more than a few lines of rules and nothing complex what so ever.
 */
.spacer {
  padding: 3rem 0; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .spacer {
      padding: 2rem 0; } }
  @media only screen and (min-width: 1px) and (max-width: 767px) {
    .spacer {
      padding: 1rem 0; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/**
 * Website Font setup
 *
 */
h1 {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 5rem;
  line-height: 7rem;
  letter-spacing: -0.43px; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    h1 {
      font-size: 2.8rem;
      line-height: 3.6rem; } }

h2 {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 4.5rem;
  line-height: 7rem;
  letter-spacing: -0.43px; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    h2 {
      font-size: 2.8rem;
      line-height: 3.6rem; } }

h3 {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 3rem;
  line-height: 7rem;
  letter-spacing: -0.29px; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    h3 {
      font-size: 2.1rem;
      line-height: 2.7rem; }
      h3.nochange {
        font-size: 2.8rem;
        line-height: 3.6rem; } }

h4 {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.3rem;
  line-height: 2.6rem;
  letter-spacing: -0.2px; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    h4 {
      font-size: 1.6rem;
      line-height: 3.1rem; }
      h4.nochange {
        font-size: 2.1rem;
        line-height: 2.7rem; } }

h5 {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 3rem; }

h1,
h2,
h3,
h4,
h5 {
  color: #383a45;
  margin-bottom: 0px; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a {
    color: #383a45;
    -webkit-text-decoration-color: #f98200;
    text-decoration-color: #f98200; }

a {
  color: #f98200;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

ul {
  margin-bottom: 0px;
  padding-left: 0px; }

p {
  color: #383a45;
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 2.6rem;
  letter-spacing: -0.13px;
  margin-top: 16px;
  margin-bottom: 0px; }
  p:first-of-type {
    margin-top: 0px; }
  p:first-child {
    margin-top: 0px; }
  p.small {
    font-size: 1.2rem;
    line-height: 1.9rem;
    letter-spacing: -0.11px; }

.defaultlink {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2.1rem;
  letter-spacing: -0.13px; }
  .defaultlink.link-yellow {
    color: #f98200; }
    .defaultlink.link-yellow:hover {
      color: #ffffff;
      text-decoration: none; }
  .defaultlink.with-arrow {
    position: relative;
    padding-right: 23px; }
    .defaultlink.with-arrow:after {
      content: "";
      width: 13px;
      height: 9px;
      position: absolute;
      background: url(./../images/right-arrow-red.svg) no-repeat center right;
      top: 60%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 0px; }

.defaultbtn {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 2.1rem;
  letter-spacing: -0.13px;
  background: #2e3091;
  color: #ffffff;
  padding: 10px 40px;
  border: 2px solid #2e3091;
  text-align: center;
  border-radius: 22.5px;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  display: inline-block;
  /*&:hover{
    background: $color-btn-hover;
  }
  &:visited, &:active{
    background: $color-btn-active;
  }*/ }
  .defaultbtn:hover {
    text-decoration: none;
    background: #ffffff;
    color: #2e3091; }
  .defaultbtn.full {
    width: 100%; }
  .defaultbtn.btn-yellow {
    background: #f98200;
    border: 2px solid #f98200; }
    .defaultbtn.btn-yellow:hover {
      color: #f98200;
      background: #ffffff; }
  .defaultbtn.btn-white {
    background: #ffffff;
    border: 2px solid #ffffff;
    color: #383a45; }
    .defaultbtn.btn-white:hover {
      color: #ffffff;
      background: #383a45; }
  .defaultbtn:focus {
    outline: none; }

body {
  padding: 0px;
  margin: 0px; }

.container {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1180px;
  padding: 0 20px; }
  @media only screen and (min-width: 1px) and (max-width: 767px) {
    .container {
      padding: 0 20px; } }

.bullet_list li {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2.1rem;
  line-height: 31.5px;
  color: #000000;
  margin-bottom: 12px;
  position: relative;
  padding-left: 32px; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .bullet_list li {
      font-family: 'Be Vietnam', sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 1.6rem;
      line-height: 2.4rem; } }
  .bullet_list li:before {
    position: absolute;
    content: "";
    top: 8px;
    /*top: 50%;
      transform: translateY(-50%);*/
    left: 0;
    width: 16px;
    height: 16px; }
    @media only screen and (min-width: 1px) and (max-width: 992px) {
      .bullet_list li:before {
        top: 5px; } }
  .bullet_list li:last-child {
    padding-bottom: 0px; }

.bullet_list.small li {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding-left: 28px;
  margin-bottom: 4px; }
  .bullet_list.small li:before {
    top: 5px; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.main_contanier .page_innter_container .page_main_area {
  padding: 52px 0 72px;
  border-bottom: 1px solid #e7e7e7; }
  .main_contanier .page_innter_container .page_main_area.blog_section {
    padding-top: 0px; }
  @media only screen and (min-width: 1px) and (max-width: 767px) {
    .main_contanier .page_innter_container .page_main_area {
      padding: 45px 0; } }

.main_contanier .page_innter_container .banner_section.banner_inner_page .banner_main_section {
  height: 420px;
  position: relative; }
  .main_contanier .page_innter_container .banner_section.banner_inner_page .banner_main_section .banner_content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1; }
    .main_contanier .page_innter_container .banner_section.banner_inner_page .banner_main_section .banner_content h2 {
      color: #ffffff; }
  .main_contanier .page_innter_container .banner_section.banner_inner_page .banner_main_section .bggrident {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.6; }

.breadcrumb {
  border-bottom: 1px solid #ddd; }
  .breadcrumb a {
    font-size: 1.3rem;
    color: #000000;
    text-decoration: none; }

.bloddate {
  margin-top: 5px;
  color: #909197; }

.detail_search_body {
  overflow: hidden; }

header {
  position: absolute;
  width: 100%;
  z-index: 9; }
  header .header-top {
    background: #151719; }
    header .header-top .header-top-inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 43px; }
      header .header-top .header-top-inner .serchform {
        position: relative; }
        header .header-top .header-top-inner .serchform .search_button {
          background-image: url("./../images/search.png");
          background-repeat: no-repeat;
          height: 18px;
          width: 17px;
          display: block; }
        header .header-top .header-top-inner .serchform.form-control {
          border: 1px solid #dadada;
          letter-spacing: -0.12px;
          color: #71727c;
          font-family: 'Be Vietnam', sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 1.4rem;
          line-height: 2.1rem;
          position: absolute;
          top: 0;
          right: 30px;
          margin-top: -4px; }
      header .header-top .header-top-inner .login_track_link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        list-style: none;
        padding-left: 5px; }
        header .header-top .header-top-inner .login_track_link li {
          padding: 0 20px;
          border-right: 1px solid #f98200; }
          header .header-top .header-top-inner .login_track_link li:last-child {
            border-right: 0px;
            padding-right: 0px; }
          header .header-top .header-top-inner .login_track_link li a {
            font-family: 'Be Vietnam', sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 1.3rem;
            line-height: 1.9rem;
            letter-spacing: -0.11px;
            color: #f98200;
            text-transform: uppercase;
            text-decoration: none; }
  header .Header-main.search-body {
    background: #000; }
  header .Header-main .header-main-inner {
    padding: 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    @media only screen and (min-width: 1px) and (max-width: 767px) {
      header .Header-main .header-main-inner {
        padding: 10px 0; } }
    header .Header-main .header-main-inner .main_nmeu_wpr .mobile-main-menu {
      display: none; }
      @media only screen and (min-width: 1px) and (max-width: 992px) {
        header .Header-main .header-main-inner .main_nmeu_wpr .mobile-main-menu {
          display: block; } }
    header .Header-main .header-main-inner .main_menu {
      list-style: none;
      padding: 0px; }
      @media only screen and (min-width: 1px) and (max-width: 992px) {
        header .Header-main .header-main-inner .main_menu {
          display: none !important; }
          header .Header-main .header-main-inner .main_menu.toggled {
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            position: absolute;
            left: 0;
            width: 100%;
            background-color: #000000;
            top: 127px;
            padding: 10px 0; } }
      header .Header-main .header-main-inner .main_menu > li {
        display: inline-block;
        padding: 0 15px;
        position: relative; }
        @media only screen and (min-width: 1px) and (max-width: 992px) {
          header .Header-main .header-main-inner .main_menu > li {
            display: inline-block;
            padding: 15px 20px;
            position: relative; } }
        header .Header-main .header-main-inner .main_menu > li:last-child {
          padding-right: 0px; }
        header .Header-main .header-main-inner .main_menu > li.menu-item-has-children > a {
          padding-right: 10px;
          cursor: pointer; }
          header .Header-main .header-main-inner .main_menu > li.menu-item-has-children > a:after {
            content: "";
            position: absolute;
            top: 57%;
            -ms-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
            border: solid #f98200;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            -webkit-transform: translateY(-50%) rotate(45deg);
            right: -5px; }
        header .Header-main .header-main-inner .main_menu > li a {
          font-family: 'Be Vietnam', sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 1.6rem;
          line-height: 2.3rem;
          color: #f98200;
          text-transform: capitalize;
          text-decoration: none;
          letter-spacing: -0.14px;
          position: relative; }
          @media only screen and (min-width: 1px) and (max-width: 992px) {
            header .Header-main .header-main-inner .main_menu > li a {
              display: block; } }
          header .Header-main .header-main-inner .main_menu > li a.active {
            color: #ffffff; }
            header .Header-main .header-main-inner .main_menu > li a.active:after {
              border-color: #ffffff;
              -ms-transform: translateY(-50%) rotate(-135deg);
              transform: translateY(-50%) rotate(-135deg);
              -webkit-transform: translateY(-50%) rotate(-135deg);
              top: 70%; }
          header .Header-main .header-main-inner .main_menu > li a:hover {
            color: #ffffff; }
            header .Header-main .header-main-inner .main_menu > li a:hover:after {
              border-color: #ffffff; }
        header .Header-main .header-main-inner .main_menu > li .sub-menu {
          display: none;
          position: absolute;
          background: #ffffff;
          padding: 15px 0;
          list-style: none;
          width: 240px;
          margin-top: 10px; }
          @media only screen and (min-width: 1px) and (max-width: 992px) {
            header .Header-main .header-main-inner .main_menu > li .sub-menu {
              position: relative;
              background: transparent; } }
          header .Header-main .header-main-inner .main_menu > li .sub-menu.true {
            display: block; }
          header .Header-main .header-main-inner .main_menu > li .sub-menu li {
            padding: 10px 20px; }
            header .Header-main .header-main-inner .main_menu > li .sub-menu li a {
              font-family: 'Be Vietnam', sans-serif;
              font-weight: 700;
              font-style: normal;
              font-size: 1.4rem;
              line-height: 2rem;
              color: #000000; }
              @media only screen and (min-width: 1px) and (max-width: 992px) {
                header .Header-main .header-main-inner .main_menu > li .sub-menu li a {
                  color: #f98200; } }
              header .Header-main .header-main-inner .main_menu > li .sub-menu li a:hover {
                color: #f98200; }

#nav-icon2 {
  width: 40px;
  height: 25px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer; }
  #nav-icon2 span {
    display: block;
    position: absolute;
    height: 4px;
    width: 50%;
    background: #f98200;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out; }
    #nav-icon2 span:nth-child(even) {
      left: 50%;
      border-radius: 0 4px 4px 0; }
    #nav-icon2 span:nth-child(odd) {
      left: 0px;
      border-radius: 4px 0 0 4px; }
    #nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
      top: 0px; }
    #nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
      top: 10px; }
    #nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
      top: 20px; }

#nav-icon2.open span:nth-child(1),
#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

#nav-icon2.open span:nth-child(2),
#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 0px; }

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 0px);
  top: 0px; }

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0; }

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0; }

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 15px; }

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 0px);
  top: 14px; }

.search-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  overflow: hidden; }
  .search-wrap form {
    width: 750px; }
  .search-wrap.open {
    height: 100%;
    background: rgba(31, 44, 54, 0.95); }
  .search-wrap .search-box {
    position: relative;
    margin: 0 auto; }
    .search-wrap .search-box .search-icon {
      background: transparent;
      color: #ffffff;
      border: none;
      font-size: 20px;
      position: absolute;
      top: 15px;
      left: 0;
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      z-index: 99; }
    .search-wrap .search-box .ant-select-selection {
      width: 700px;
      position: relative;
      cursor: pointer;
      font-size: 2rem !important;
      font-weight: 400 !important;
      line-height: 3em !important;
      height: 3em;
      letter-spacing: 1px;
      margin: 0;
      padding: 0 2em;
      border: none;
      white-space: pre-wrap;
      word-wrap: break-word;
      resize: none;
      overflow: hidden;
      display: block;
      background: transparent;
      visibility: visible;
      border-bottom: 1px solid #ddd;
      color: #ffffff;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none; }
      @media only screen and (min-width: 1px) and (max-width: 767px) {
        .search-wrap .search-box .ant-select-selection {
          width: 500px; } }
      @media only screen and (min-width: 1px) and (max-width: 599px) {
        .search-wrap .search-box .ant-select-selection {
          width: 300px; } }
      .search-wrap .search-box .ant-select-selection:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none; }
      .search-wrap .search-box .ant-select-selection .ant-select-selection__rendered {
        line-height: 6rem; }
    .search-wrap .search-box .close-button {
      position: absolute;
      top: 20%;
      right: 1%;
      font-size: 20px; }

.search-blog {
  padding-top: 140px; }
  .search-blog .search-text {
    text-align: center;
    padding: 50px 0; }
    @media only screen and (min-width: 1px) and (max-width: 767px) {
      .search-blog .search-text {
        padding: 10px 0; } }
  .search-blog .search-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding-bottom: 25px;
    padding-top: 25px; }
    @media only screen and (min-width: 1px) and (max-width: 767px) {
      .search-blog .search-title {
        display: block; } }
    .search-blog .search-title h5 {
      font-size: 17px;
      width: 50%;
      line-height: 21px;
      font-weight: 400; }
      @media only screen and (min-width: 1px) and (max-width: 767px) {
        .search-blog .search-title h5 {
          width: 100%;
          margin-bottom: 20px; } }
    .search-blog .search-title h6 {
      font-size: 17px;
      width: 15%;
      line-height: 21px;
      font-weight: bold;
      color: #afafaf; }
      @media only screen and (min-width: 1px) and (max-width: 767px) {
        .search-blog .search-title h6 {
          width: 100%;
          margin-bottom: 20px; } }
  .search-blog .search-results h2 {
    color: #ccd1d7;
    font-size: 70px;
    text-align: center;
    margin-bottom: 50px; }
    @media only screen and (min-width: 1px) and (max-width: 767px) {
      .search-blog .search-results h2 {
        font-size: 45px;
        line-height: 50px; } }
  .search-blog .serch-form {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    position: relative; }
    .search-blog .serch-form img {
      width: 100%; }
    .search-blog .serch-form .search_field {
      -webkit-appearance: none;
      outline: none;
      padding: 1.2em 3em 1em 1em;
      border: 1px solid #ddd;
      border-radius: 3px;
      width: 500px;
      max-width: 100%;
      height: 3.584em;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 15px;
      margin: 0 auto; }
    .search-blog .serch-form span {
      position: absolute;
      top: 29%;
      right: 29%;
      font-size: 15px;
      color: #afafaf; }

.image-wpr img {
  width: 100%; }

.call-to-access {
  padding: 70px 0; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .call-to-access {
      padding: 40px 0; } }
  .call-to-access .call-to-access-inner {
    width: 625px;
    max-width: 100%;
    text-align: center;
    margin: 0 auto; }
    .call-to-access .call-to-access-inner p {
      padding-top: 0px; }
    .call-to-access .call-to-access-inner .defaultbtn {
      margin-top: 32px; }

.footer-section {
  background: #222231; }
  .footer-section .footer-top-inner {
    padding: 80px 0 60px;
    border-bottom: 1px solid #333342; }
    @media only screen and (min-width: 1px) and (max-width: 992px) {
      .footer-section .footer-top-inner {
        padding: 50px 0 30px; } }
    @media only screen and (min-width: 1px) and (max-width: 992px) {
      .footer-section .footer-top-inner .contact-info {
        text-align: center; } }
    @media only screen and (min-width: 1px) and (max-width: 767px) {
      .footer-section .footer-top-inner .contact-info {
        text-align: left; } }
    .footer-section .footer-top-inner .contact-info .footer_logo {
      margin-bottom: 35px; }
    .footer-section .footer-top-inner .contact-info p.small {
      margin-bottom: 0px;
      color: #ffffff; }
    .footer-section .footer-top-inner .contact-info .emil-tel {
      margin-top: 35px;
      padding: 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      @media only screen and (min-width: 1px) and (max-width: 992px) {
        .footer-section .footer-top-inner .contact-info .emil-tel {
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center; } }
      @media only screen and (min-width: 1px) and (max-width: 767px) {
        .footer-section .footer-top-inner .contact-info .emil-tel {
          -ms-flex-flow: wrap;
          flex-flow: wrap;
          margin-top: 25px; } }
      .footer-section .footer-top-inner .contact-info .emil-tel li {
        position: relative;
        padding-right: 25px; }
        @media only screen and (min-width: 1px) and (max-width: 767px) {
          .footer-section .footer-top-inner .contact-info .emil-tel li {
            margin-bottom: 10px;
            padding-right: 0px;
            width: 100%; } }
        .footer-section .footer-top-inner .contact-info .emil-tel li:last-child {
          padding-right: 0px; }
        .footer-section .footer-top-inner .contact-info .emil-tel li a {
          padding-left: 25px;
          font-family: 'Be Vietnam', sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 1.6rem;
          line-height: 1.6rem;
          letter-spacing: -0.14px;
          color: #ffffff; }
          @media only screen and (min-width: 1px) and (max-width: 767px) {
            .footer-section .footer-top-inner .contact-info .emil-tel li a {
              position: relative; } }
          .footer-section .footer-top-inner .contact-info .emil-tel li a:hover {
            text-decoration: none; }
          .footer-section .footer-top-inner .contact-info .emil-tel li a.tel:before {
            content: "";
            width: 13px;
            height: 13px;
            position: absolute;
            background: url(./../images/telephone-handle-silhouette.png) no-repeat center right;
            left: 0px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
          .footer-section .footer-top-inner .contact-info .emil-tel li a.mail:before {
            content: "";
            width: 13px;
            height: 13px;
            position: absolute;
            background: url(./../images/envelope.png) no-repeat center right;
            left: 0px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
    .footer-section .footer-top-inner .footer_menu {
      padding: 0px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      @media only screen and (min-width: 1px) and (max-width: 992px) {
        .footer-section .footer-top-inner .footer_menu {
          padding-top: 30px; } }
      @media only screen and (min-width: 1px) and (max-width: 767px) {
        .footer-section .footer-top-inner .footer_menu > li {
          margin-top: 20px; }
          .footer-section .footer-top-inner .footer_menu > li:first-child {
            margin-top: 0px; } }
      .footer-section .footer-top-inner .footer_menu > li .menu_title {
        color: #ffffff; }
      .footer-section .footer-top-inner .footer_menu > li .sub-menu {
        display: block;
        padding: 0;
        width: 100%;
        margin-top: 18px; }
        .footer-section .footer-top-inner .footer_menu > li .sub-menu li {
          padding-left: 28px;
          position: relative; }
          .footer-section .footer-top-inner .footer_menu > li .sub-menu li a {
            font-family: 'Be Vietnam', sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 1.4rem;
            line-height: 4.4rem;
            color: #818183; }
            @media only screen and (min-width: 1px) and (max-width: 767px) {
              .footer-section .footer-top-inner .footer_menu > li .sub-menu li a {
                font-size: 1.4rem;
                line-height: 3.4rem; } }
            .footer-section .footer-top-inner .footer_menu > li .sub-menu li a:hover {
              text-decoration: none; }
          .footer-section .footer-top-inner .footer_menu > li .sub-menu li:before {
            content: "";
            width: 8px;
            height: 8px;
            position: absolute;
            background: #f98200;
            left: 0px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            border-radius: 50%; }
  .footer-section .footer-bottom-inner {
    padding: 32px 0 64px; }
    @media only screen and (min-width: 1px) and (max-width: 992px) {
      .footer-section .footer-bottom-inner {
        padding: 32px 0 40px; } }
    .footer-section .footer-bottom-inner .footer-copy-right ul {
      padding: 0px;
      list-style: none;
      margin-bottom: 0px; }
      .footer-section .footer-bottom-inner .footer-copy-right ul li {
        padding: 0px 15px 2px;
        border-right: 1px solid #ffffff;
        -webkit-box-sizing: border-box;
        /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;
        /* Firefox, other Gecko */
        box-sizing: border-box; }
        .footer-section .footer-bottom-inner .footer-copy-right ul li:last-child {
          border-right: 0px; }
        @media only screen and (min-width: 1px) and (max-width: 767px) {
          .footer-section .footer-bottom-inner .footer-copy-right ul li {
            border: 0px; } }
        .footer-section .footer-bottom-inner .footer-copy-right ul li p {
          font-family: 'Be Vietnam', sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 1.3rem;
          line-height: 2rem;
          color: #ffffff; }
        .footer-section .footer-bottom-inner .footer-copy-right ul li a {
          font-family: 'Be Vietnam', sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 1.3rem;
          line-height: 2rem;
          color: #ffffff; }
    @media only screen and (min-width: 1px) and (max-width: 992px) {
      .footer-section .footer-bottom-inner .footer-social {
        margin-top: 30px; } }
    .footer-section .footer-bottom-inner .footer-social .social-lists {
      padding: 0px;
      list-style: none;
      margin-bottom: 0; }
      .footer-section .footer-bottom-inner .footer-social .social-lists li {
        position: relative;
        padding: 0 7px; }
        .footer-section .footer-bottom-inner .footer-social .social-lists li:last-child {
          padding-right: 0px; }
          @media only screen and (min-width: 1px) and (max-width: 767px) {
            .footer-section .footer-bottom-inner .footer-social .social-lists li:last-child {
              padding-right: 7px; } }
        .footer-section .footer-bottom-inner .footer-social .social-lists li a {
          width: 38px;
          height: 38px;
          background: #2c2d3d;
          border-radius: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: space-evenly;
          -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
          text-decoration: none; }
          .footer-section .footer-bottom-inner .footer-social .social-lists li a i {
            color: #7D7F8B;
            font-size: 18px; }
          .footer-section .footer-bottom-inner .footer-social .social-lists li a:hover {
            background: #ffffff;
            text-decoration: none; }
            .footer-section .footer-bottom-inner .footer-social .social-lists li a:hover i {
              color: #383a45; }

.slick-slider .slick-list .slick-track .slick-slide .slick-slider-item {
  position: relative; }
  .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner {
    height: 840px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
    @media only screen and (min-width: 1px) and (max-width: 767px) {
      .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner {
        max-height: 100vh; } }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
      .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner {
        max-height: 120vh; } }
    .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .bggrident {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.5;
      top: 0px; }
    .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content {
      position: relative;
      z-index: 1;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      max-width: 715px;
      z-index: 1;
      text-align: center; }
      @media only screen and (min-width: 1px) and (max-width: 992px) {
        .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content {
          padding: 0 40px;
          top: 50%; } }
      .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content h2,
      .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content p {
        color: #ffffff; }
      @media only screen and (min-width: 1px) and (max-width: 992px) {
        .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content h2 {
          margin-bottom: 15px; } }
      .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content p {
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 1.6rem;
        line-height: 2.6rem;
        letter-spacing: -0.14px; }
      @media screen and (max-device-width: 767px) and (orientation: landscape) {
        .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content {
          top: 65%; } }
      @media only screen and (min-width: 1px) and (max-width: 359px) {
        .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content {
          top: 62%; } }
      .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content .button-wpr {
        margin-top: 30px; }
        @media screen and (max-device-width: 767px) and (orientation: landscape) {
          .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content .button-wpr {
            margin-top: 20px; } }
        .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content .button-wpr a.defaultbtn {
          margin: 0 8px;
          -webkit-animation: fadeInDown 1s both 1s;
          animation: fadeInDown 1s both 1s; }
          @media only screen and (min-width: 1px) and (max-width: 767px) {
            .slick-slider .slick-list .slick-track .slick-slide .slick-slider-item .slider-inner .slider-content .button-wpr a.defaultbtn {
              margin: 0px 8px 10px; } }

.slick-slider .slick-next {
  right: 50px;
  width: 44px;
  height: 44px; }
  .slick-slider .slick-next:before {
    background: url(./../images/right-arrow.svg) no-repeat; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .slick-slider .slick-next {
      right: 20px; } }
  @media only screen and (min-width: 1px) and (max-width: 767px) {
    .slick-slider .slick-next {
      right: 5px; } }

.slick-slider .slick-prev {
  left: 50px;
  width: 44px;
  height: 44px;
  z-index: 1; }
  .slick-slider .slick-prev:before {
    background: url(./../images/left-arrow.svg) no-repeat; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .slick-slider .slick-prev {
      left: 20px; } }
  @media only screen and (min-width: 1px) and (max-width: 767px) {
    .slick-slider .slick-prev {
      left: 5px; } }

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
  content: "";
  width: 44px;
  height: 44px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1; }

.home_about_intro {
  padding: 70px 0; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .home_about_intro {
      padding: 40px 0; } }
  .home_about_intro .two-part-content {
    padding-top: 70px; }
    @media only screen and (min-width: 1px) and (max-width: 992px) {
      .home_about_intro .two-part-content {
        padding-top: 40px; } }
    .home_about_intro .two-part-content .left-part-content a.defaultbtn {
      margin-top: 20px; }
    .home_about_intro .two-part-content .left-part-content .about-intro-left-img {
      margin-top: 25px;
      text-align: center; }
      .home_about_intro .two-part-content .left-part-content .about-intro-left-img > img {
        margin: 0 auto;
        max-width: 100%; }
    @media only screen and (min-width: 1px) and (max-width: 992px) {
      .home_about_intro .two-part-content .image-wpr {
        margin-top: 40px; } }

.home_real_time {
  background: url(./../images/realtimetracking_home.jpg) no-repeat center;
  display: block;
  position: relative;
  padding: 75px 0 90px;
  color: #ffffff;
  text-align: center;
  background-size: cover; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .home_real_time {
      padding: 40px 0 50px; } }
  .home_real_time h2 {
    color: #ffffff; }
  .home_real_time p {
    color: #ffffff; }
  .home_real_time .subheading_real_time {
    margin-top: 20px; }
  .home_real_time .real-time-list-items {
    background: #f98200;
    border-radius: 4px;
    padding: 40px;
    text-align: left;
    max-width: 425px;
    margin-top: 40px; }
    .home_real_time .real-time-list-items ul {
      padding-left: 30px;
      margin-top: 22px; }
      .home_real_time .real-time-list-items ul li {
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 2.6rem;
        letter-spacing: -0.13px; }
    .home_real_time .real-time-list-items .button-wpr {
      text-align: center;
      margin-top: 30px; }

.service_slider_wpr {
  background: url(./../images/service_bg.png) no-repeat center;
  display: block;
  position: relative;
  padding: 90px 50px;
  background-size: cover; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .service_slider_wpr {
      padding: 50px 20px; } }
  .service_slider_wpr .our_service_header {
    padding-bottom: 70px; }
    @media only screen and (min-width: 1px) and (max-width: 992px) {
      .service_slider_wpr .our_service_header {
        padding-bottom: 40px; } }
    .service_slider_wpr .our_service_header h2 {
      color: #ffffff; }
    .service_slider_wpr .our_service_header .our_service_navigation {
      position: relative;
      top: -30px; }
      @media only screen and (min-width: 1px) and (max-width: 992px) {
        .service_slider_wpr .our_service_header .our_service_navigation {
          top: -20px; } }
      @media only screen and (min-width: 1px) and (max-width: 359px) {
        .service_slider_wpr .our_service_header .our_service_navigation {
          top: -13px; } }
      .service_slider_wpr .our_service_header .our_service_navigation .slick-next {
        right: 0px;
        width: 45px;
        height: 45px; }
        .service_slider_wpr .our_service_header .our_service_navigation .slick-next:before {
          background: url(./../images/right.svg) no-repeat;
          background-size: 100%; }
        .service_slider_wpr .our_service_header .our_service_navigation .slick-next:hover:before {
          background: url(./../images/white-right.svg) no-repeat;
          background-size: 100%; }
        @media only screen and (min-width: 1px) and (max-width: 359px) {
          .service_slider_wpr .our_service_header .our_service_navigation .slick-next {
            width: 30px;
            height: 30px; } }
      .service_slider_wpr .our_service_header .our_service_navigation .slick-prev {
        left: initial;
        width: 45px;
        height: 45px;
        z-index: 1;
        right: 50px; }
        .service_slider_wpr .our_service_header .our_service_navigation .slick-prev:before {
          background: url(./../images/left.svg) no-repeat; }
        .service_slider_wpr .our_service_header .our_service_navigation .slick-prev:hover:before {
          background: url(./../images/white-left.svg) no-repeat; }
        @media only screen and (min-width: 1px) and (max-width: 359px) {
          .service_slider_wpr .our_service_header .our_service_navigation .slick-prev {
            width: 30px;
            height: 30px;
            right: 35px; } }
      .service_slider_wpr .our_service_header .our_service_navigation .slick-prev:before,
      .service_slider_wpr .our_service_header .our_service_navigation .slick-next:before {
        content: "";
        width: 45px;
        height: 45px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1; }
        @media only screen and (min-width: 1px) and (max-width: 359px) {
          .service_slider_wpr .our_service_header .our_service_navigation .slick-prev:before,
          .service_slider_wpr .our_service_header .our_service_navigation .slick-next:before {
            width: 30px;
            height: 30px;
            background-size: 100% auto; } }
  .service_slider_wpr .slick-slider {
    height: auto; }
    .service_slider_wpr .slick-slider .slick-list .slick-track {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch; }
      .service_slider_wpr .slick-slider .slick-list .slick-track:focus {
        outline: none; }
      .service_slider_wpr .slick-slider .slick-list .slick-track .slick-slide {
        float: none;
        min-height: inherit;
        height: auto; }
        .service_slider_wpr .slick-slider .slick-list .slick-track .slick-slide > div {
          height: 100%;
          background-color: #2e3091;
          margin: 0 12.5px;
          border-radius: 6px; }
  .service_slider_wpr .slick-service-slider:focus {
    outline: none; }
  .service_slider_wpr .slick-service-slider .slider-inner .slider-content {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding: 50px 40px; }
    .service_slider_wpr .slick-service-slider .slider-inner .slider-content:hover {
      -ms-transform: translateY(10px);
      transform: translateY(10px);
      -webkit-transform: translateY(10px);
      -moz-transform: translateY(10px);
      -o-transform: translateY(10px);
      transform: translateY(10px); }
    .service_slider_wpr .slick-service-slider .slider-inner .slider-content:focus {
      outline: none; }
    .service_slider_wpr .slick-service-slider .slider-inner .slider-content .service_img {
      max-height: 60px; }
    .service_slider_wpr .slick-service-slider .slider-inner .slider-content h4 {
      padding-top: 20px;
      color: #ffffff; }
    .service_slider_wpr .slick-service-slider .slider-inner .slider-content p {
      color: #ffffff; }
    .service_slider_wpr .slick-service-slider .slider-inner .slider-content .button-wpr {
      margin-top: 25px; }

.full_image {
  margin-top: 45px; }
  .full_image > img {
    width: 100%;
    max-width: 100%; }

.two-part-content {
  margin-top: 45px; }
  .two-part-content:first-of-type {
    margin-top: 0px; }
  .two-part-content .image-wpr {
    text-align: center; }
    .two-part-content .image-wpr > img {
      max-width: 100%; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .two-part-content .right-part-content {
      margin-top: 45px; } }
  .two-part-content .right-part-content > img {
    max-width: 100%; }

.about_bottom_content {
  margin-top: 45px; }

.service_inner_content ul {
  padding-left: 20px;
  margin-top: 16px; }
  .service_inner_content ul:first-of-type {
    margin-top: 0px; }
  .service_inner_content ul li {
    font-family: 'Be Vietnam', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 2.6rem;
    letter-spacing: -0.13px; }

@media only screen and (min-width: 1px) and (max-width: 992px) {
  .about_us_conaten .two-part-content .right-part-content {
    margin-top: 45px; } }

.technology_inner_content .body-inner-content {
  background-image: url("./../images/technology1.png");
  background-image: url("./../images/technology1.png"), -webkit-linear-gradient(337deg, #2e3091 30%, rgba(46, 48, 145, 0.9) 83%);
  background-image: url("./../images/technology1.png"), -o-linear-gradient(337deg, #2e3091 30%, rgba(46, 48, 145, 0.9) 83%);
  background-image: url("./../images/technology1.png"), linear-gradient(113deg, #2e3091 30%, rgba(46, 48, 145, 0.9) 83%);
  padding: 58px;
  color: #ffffff; }
  @media only screen and (min-width: 1px) and (max-width: 767px) {
    .technology_inner_content .body-inner-content {
      padding: 30px 20px; } }
  .technology_inner_content .body-inner-content h1,
  .technology_inner_content .body-inner-content h2,
  .technology_inner_content .body-inner-content h3,
  .technology_inner_content .body-inner-content h4,
  .technology_inner_content .body-inner-content h5,
  .technology_inner_content .body-inner-content h6 {
    color: #ffffff;
    margin-bottom: 18px; }
  .technology_inner_content .body-inner-content ul {
    padding-left: 0px;
    margin-top: 22px;
    list-style: none;
    display: -webkit-box;
    display: flex;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .technology_inner_content .body-inner-content ul li {
      font-family: 'Be Vietnam', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 1.4rem;
      line-height: 4.4rem;
      position: relative;
      padding-left: 35px;
      width: 50%; }
      @media only screen and (min-width: 1px) and (max-width: 767px) {
        .technology_inner_content .body-inner-content ul li {
          width: 100%;
          padding-left: 30px;
          line-height: 3.4rem; } }
      .technology_inner_content .body-inner-content ul li:before {
        content: "\2022";
        color: #f98200;
        font-weight: bold;
        display: inline-block;
        font-size: 2.5em;
        position: absolute;
        left: 5px; }

.technology_inner_content .single-image-tech {
  margin-top: 100px; }
  @media only screen and (min-width: 1px) and (max-width: 767px) {
    .technology_inner_content .single-image-tech {
      margin-top: 50px; } }

.request_quote_module .two-part-content .left-part-content.image-wpr {
  margin-top: 25px; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .request_quote_module .two-part-content .left-part-content.image-wpr {
      margin-top: 0px; } }
  .request_quote_module .two-part-content .left-part-content.image-wpr > img {
    width: 100%; }

.request_quote_module .two-part-content .right-part-content .form_section {
  padding-left: 30px;
  max-width: 576px; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .request_quote_module .two-part-content .right-part-content .form_section {
      padding-left: 0px;
      max-width: 100%; } }

.form_section form {
  margin-top: 50px; }
  .form_section form .form-group {
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 30px; }
    .form_section form .form-group .form-item {
      padding-left: 10px;
      padding-right: 10px; }
      @media only screen and (min-width: 1px) and (max-width: 767px) {
        .form_section form .form-group .form-item {
          margin-top: 30px; }
          .form_section form .form-group .form-item:first-of-type {
            margin-top: 0px; } }
      .form_section form .form-group .form-item input,
      .form_section form .form-group .form-item textarea {
        border: 1px solid #dadada;
        letter-spacing: -0.12px;
        color: #71727c;
        padding: 15px 15px 14px;
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 1.4rem;
        line-height: 2.1rem;
        height: auto; }
        .form_section form .form-group .form-item input.error,
        .form_section form .form-group .form-item textarea.error {
          border-color: red; }
      .form_section form .form-group .form-item .errorMessage {
        color: red; }
      .form_section form .form-group .form-item .selectbox.error > div:first-of-type {
        border-color: red;
        outline: none; }
      .form_section form .form-group .form-item .selectbox > div:first-of-type {
        border: 1px solid #dadada;
        letter-spacing: -0.12px;
        color: #71727c;
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 1.4rem;
        line-height: 2.1rem;
        height: 52px; }

.contactinfo {
  max-width: 364px;
  background: #2e3091;
  padding: 45px 35px; }
  .contactinfo .contentinfo_list {
    list-style: none; }
    .contactinfo .contentinfo_list li {
      position: relative;
      padding-left: 50px;
      color: #ffffff;
      margin-bottom: 50px;
      font-family: 'Be Vietnam', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 1.5rem;
      line-height: 2.1rem;
      letter-spacing: -0.13px; }
      .contactinfo .contentinfo_list li:last-child {
        margin-bottom: 30px; }
      .contactinfo .contentinfo_list li:before {
        content: "";
        background-image: url("./../images/place.svg");
        width: 25px;
        height: 31px;
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 5px; }
      .contactinfo .contentinfo_list li.telephone:before {
        background-image: url("./../images/phone.svg");
        width: 28px;
        height: 28px; }
      .contactinfo .contentinfo_list li.email:before {
        background-image: url("./../images/envelope.svg");
        width: 26px;
        height: 20px; }
      .contactinfo .contentinfo_list li h4 {
        color: #ffffff;
        margin-bottom: 15px; }
      .contactinfo .contentinfo_list li p {
        margin-top: 0px;
        color: #ffffff; }

.conatct_track_and_map_section {
  background: #2e3091;
  color: #ffffff;
  padding: 60px 0 80px; }
  .conatct_track_and_map_section h2,
  .conatct_track_and_map_section h3,
  .conatct_track_and_map_section h4,
  .conatct_track_and_map_section h5,
  .conatct_track_and_map_section h6,
  .conatct_track_and_map_section p {
    color: #ffffff; }
  .conatct_track_and_map_section .two-part-content .image-wpr > img {
    width: 100%;
    height: 100%; }
  .conatct_track_and_map_section .heading-main {
    text-align: center;
    margin-bottom: 50px; }
  .conatct_track_and_map_section .contact-box {
    background-image: url("./../images/TrackPackage.jpg");
    padding: 25px 88px 25px 25px;
    background-size: cover;
    border-radius: 5px;
    margin-top: 30px; }
    .conatct_track_and_map_section .contact-box:first-child {
      margin-top: 0px; }
      @media only screen and (min-width: 1px) and (max-width: 992px) {
        .conatct_track_and_map_section .contact-box:first-child {
          margin-top: 30px; } }
    .conatct_track_and_map_section .contact-box.career_package {
      background-image: url("./../images/Career.jpg"); }
    .conatct_track_and_map_section .contact-box .packinfo {
      padding-top: 8px;
      padding-bottom: 8px; }
    .conatct_track_and_map_section .contact-box .packagelink {
      background-image: url("./../images/package-arrow.svg");
      background-repeat: no-repeat;
      width: 36px;
      height: 36px;
      display: inline-block; }

.tracking_package_list .row {
  margin-top: 70px; }
  @media only screen and (min-width: 1px) and (max-width: 992px) {
    .tracking_package_list .row > div {
      margin-bottom: 30px; } }
  .tracking_package_list .row > div .item {
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 4px;
    border-radius: 4px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    @media only screen and (min-width: 1px) and (max-width: 992px) {
      .tracking_package_list .row > div .item {
        min-height: 170px; } }
    .tracking_package_list .row > div .item > img {
      max-width: 100%; }

.bloglisting {
  list-style: none; }
  .bloglisting .blogitem {
    border-bottom: 1px solid #e7e7e7;
    padding: 20px 0; }
    .bloglisting .blogitem:first-child {
      padding-top: 0px; }
    .bloglisting .blogitem .blogimg {
      text-align: center; }
      .bloglisting .blogitem .blogimg > img {
        max-width: 100%;
        width: 100%; }
    .bloglisting .blogitem .bloginfo .blogtitle {
      font-family: 'Be Vietnam', sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 1.5rem;
      line-height: 2.6rem; }
    .bloglisting .blogitem .bloginfo .bloddate {
      margin-top: 5px;
      color: #909197; }
    .bloglisting .blogitem .bloginfo .short_info {
      margin-top: 16px;
      margin-bottom: 20px; }
    .bloglisting .blogitem .bloginfo .defaultlink.link-yellow:hover {
      color: #383a45; }

.blog_content .blog_left {
  padding-top: 52px; }
  @media only screen and (min-width: 1px) and (max-width: 767px) {
    .blog_content .blog_left {
      padding-top: 45px; } }

@media only screen and (min-width: 1px) and (max-width: 992px) {
  .blog_right {
    margin-top: 45px; } }

.blog_right .blogtopics {
  border-top: 3px solid #f98200;
  background: #2e3091;
  padding: 20px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box; }
  .blog_right .blogtopics h5 {
    color: #f98200; }
  .blog_right .blogtopics .catlisting {
    list-style: none;
    margin-top: 18px; }
    .blog_right .blogtopics .catlisting li {
      padding-left: 35px;
      position: relative; }
      .blog_right .blogtopics .catlisting li a {
        font-family: 'Be Vietnam', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 3.6rem;
        letter-spacing: -0.13px;
        color: #ffffff;
        position: relative; }
        @media only screen and (min-width: 1px) and (max-width: 767px) {
          .blog_right .blogtopics .catlisting li a {
            font-size: 1.4rem;
            line-height: 3.4rem; } }
        .blog_right .blogtopics .catlisting li a:hover {
          text-decoration: none; }
      .blog_right .blogtopics .catlisting li:before {
        content: "";
        width: 4px;
        height: 4px;
        position: absolute;
        background: #ffffff;
        left: 10px;
        top: 55%;
        -webkit-transform: translateY(-55%);
        -ms-transform: translateY(-55%);
        transform: translateY(-55%);
        border-radius: 50%; }

.blog_right .offerwpr {
  margin-top: 15px;
  text-align: center; }

.load_more_wpr {
  text-align: center;
  margin-top: 50px; }
